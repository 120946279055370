<template>
  <v-dialog
    v-model="dialog"
    width="800"
    persistent
    class="style-dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn :disabled='disabled' text @click="showDialog()" color='primary'>
        <span class='hidden-sm-and-down font-weight-bold'>{{ $t('tokens.buttons.sendToken') }}</span>

        <v-icon color='primary' size='20px'>
          mdi-arrow-right-box
        </v-icon>
      </v-btn>
    </template>

    <v-card class="card pa-2">
      <!--   LOADING METAMASK   -->
      <OverlayLoadingMetamask :loading='loadings.send' />

      <v-card-title class="text--secondary d-flex justify-space-between align-center align-content-center card-title">
        <span class='headline font-weight-bold text-uppercase'>
          {{ $t('tokens.buttons.sendToken') }}
          <v-icon right color='primary' size='20px'>
            mdi-arrow-right-thick
          </v-icon>
        </span>
        <div class='d-flex justify-start align-center'>
          <div class='d-flex justify-start align-center align-content-center'>
            <p class='ma-0 px-2 token-name'>
              {{ token.product }}
            </p>
            <p class='ma-0 px-2 token-address__default'>
              {{ token.symbol }}
            </p>
          </div>
        </div>
      </v-card-title>

      <v-divider />

      <v-card-text class='pa-4'>
        <v-form ref="formLocationToken" v-model="validLocation" class="mb-3">
          <v-row class='d-flex justify-start form-container'>
            <v-col cols='6'>
              <v-label for='wallet-address'>{{ $t('tokens.fields.walletAddress') }}: </v-label>
              <v-autocomplete
                  :placeholder="$t('tokens.fields.walletAddress')"
                  :rules="requireRules"
                  :items="wallets"
                  v-model="wallet"
                  small
                  flat
                  solo
                  dense
                  background-color="#EAEAEA80"
                  clearable
                  color="primary"
                  item-text="name"
                  item-color="primary"
                  return-object
              >
                <template v-slot:label>
                  {{ $t('tokens.fields.walletAddress') }}
                  <span class="red--text"><strong>*</strong></span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-row class="ma0 pa-0 d-flex justify-space-between align-center align-content-center">
                <v-col cols="6">
                  <v-autocomplete
                      v-model="primary"
                      :clearable="true"
                      :hide-selected="true"
                      :no-data-text="$t('common.fields.noDataAvailable')"
                      :rules="requireRules"
                      :label="$t('documents.fields_add.locations')"
                      background-color="#EAEAEA80"
                      class="required"
                      color="primary"
                      item-text="name"
                      item-value="id"
                      persistent-hint
                      dense
                      small
                      small-chips
                      outlined
                      :items="locations"
                      @change="
                        fetchStorages({
                        primaryLocationVersionIds: primary,
                        isActive: true
                      })"
                      @update:search-input="
                        fetchLocations({
                        isActive: true
                      })"
                      @click:clear="clearLocations"
                  >
                    <template v-slot:label>
                      {{ $t('season.fields.storage_device') }}
                      <strong class="red--text">*</strong>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                      v-model="secondary"
                      :clearable="true"
                      :hide-selected="true"
                      :items="storages"
                      :no-data-text="$t('common.fields.noDataAvailable')"
                      :rules="requireRules"
                      :label="$t('storage.label')"
                      :loading="loadings.secondary"
                      background-color="#EAEAEA80"
                      class="required"
                      color="primary"
                      item-text="control_label"
                      item-value="id"
                      persistent-hint
                      dense
                      small
                      small-chips
                      outlined
                      :disabled="!primary"
                      @update:search-input="
                        fetchStorages({
                        primaryLocationVersionIds: primary,
                        isActive: true,
                        stringFilter: $event
                      })"
                  >
                    <template v-slot:label>
                      {{ $t('storage.label') }}
                      <span class="red--text"><strong>*</strong></span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-col cols="12" class="pa-4">
            <v-data-table
              :footer-props="{
                itemsPerPageText: $t('common.table.itemsPerPageText'),
                pageText: '{0}-{1} ' + $t('common.table.pageText') + ' {2}',
              }"
              :headers="headers"
              :items="lots"
              :loading="loading"
              :no-data-text="$t('common.ui.not_data_found')"
              :no-results-text="$t('common.ui.not_search_found')"
              :search="search"
              show-select
              v-model="selected"
              @item-selected="isSelected($event)"
              height="200px"
              single-select
            >
              <template v-slot:top>
                <v-toolbar color="white" flat>
                  <v-row class="d-flex justify-space-between align-baseline pb-5">
                    <v-col class="pl-0" cols="6" lg="4" md="4" sm="6" xl="4">
                      <v-text-field
                          v-model="search"
                          :clearable="true"
                          :label="$t('common.titles.filter')"
                          append-icon="mdi-magnify"
                          color="primary"
                          dense
                          filled
                          hide-details
                          onkeypress="return (event.charCode != 34)"
                          rounded
                          single-line
                          item-key="id"
                      />
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="4" class="d-flex justify-end">
                      <strong>{{ $t('inventory.titles.totalToSend') }}
                        <v-chip small class="default">
                          {{ sum() }}
                        </v-chip>
                      </strong>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </template>
              <!-- ...existing slots for data table... -->
              <template v-slot:item.locations="{ item }">
                {{ item.locations.primary.name }} | {{ item.locations.secondary.name }}
              </template>
              <template v-slot:item.available="{ item }">
                <v-chip small :class="amount(item) >= 0 ? 'success' : 'error'">
                  {{ amount(item) }}
                </v-chip>
              </template>
              <template v-slot:item.amount="{ item }">
                <v-form ref="formAmount" v-model="valid[item.id]" v-if="item.isSelected">
                  <v-text-field
                      class="ma-1"
                      :disabled="loading"
                      :placeholder="$t('tokens.fields.quantity')"
                      :hide-details="!(amounts[item.id] > item.available)"
                      :rules="validateMoreThan(0, item.available)"
                      :min="0"
                      @input="isEmpty(amounts[item.id], item.id)"
                      v-model="amounts[item.id]"
                      background-color="#EAEAEA80"
                      color="primary"
                      flat
                      small
                      solo
                      dense
                      type="number"
                  />
                </v-form>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn
          small
          text
          color="neuter"
          @click="closeDialog()"
          :disabled="loadings.send"
        >
          {{ $t('common.buttons.cancel') }}
        </v-btn>
        <ConfirmDialog :valid="!(lots.length > 0) || isValid || !moreThan || !validLocation || loadings.send"
                       :loading="loadings.send"
                       :btn-title="$t('tokens.buttons.sendToken')"
                       :is-tooltip="false"
                       :is-btn="true"
                       :is-icon="false"
                       btn-color="primary"
                       action="sendTokens"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import i18n from "@/plugins/i18n";
import { mapActions, mapGetters } from 'vuex'
import ReadMore from "@/components/ReadMoreComponent";
import OverlayLoadingMetamask from '@/module/tokens/commons/OverlayLoadingMetamask'
import ConfirmDialog from '@/components/ConfirmDialog'
import {locationMixin} from "@/mixins/location.mixin";
import {storageMixin} from "@/mixins/storage.mixin";
import sortList from "@/services/OrderListService";

  export default {
    name: 'WalletSendDialog',
    components: { ConfirmDialog, ReadMore, OverlayLoadingMetamask },
    props: {
      token: {
        type: Object,
        required: true,
        default: () => {
          return {}
        }
      },
      disabled: {
        type: Boolean,
        required: false,
        default: true
      }
    },

    data:() => ({
      loading: false,
      loadingLots: false,
      launchLoadingMetamask: false,
      dialog: false,
      wallet: null,
      search: null,
      selectToken: null,
      valid: {},
      validLocation: false,
      selected: [],
      amounts: [],
      primary: null,
      secondary: null,
    }),

    mixins: [locationMixin, storageMixin],

    mounted() {
      this.$root.$on("sendTokens", async () => {
        await this.sendTokens();
      });
    },

    computed: {
      ...mapGetters({
        wallets: 'web3/GET_WALLETS',
        requireRules: 'general/requireRules',
        onlyPositiveNumberFormat: 'general/onlyPositiveNumberFormat',
        validateAddress: 'general/ethereumAddress',
        validateMoreThan: 'general/validateMoreThan',
        selectRule: 'general/selectRule',
        loadings: 'web3/GET_LOADINGS'
      }),
      locationsFiltered() {
        return this.getLocationsByAction()
      },
      storageDevicesFiltered() {
        return this.getStorageFiltered()
      },
      lots: {
        get() {
          return this.$store.getters['web3/GET_LOTS']
        },
        set(val) {
          this.$store.commit('web3/SET_LOTS', val)
        }
      },
      headers() {
        return [{
          text: i18n.t('inventory.titles.lots'), value: "lot", val: "lot"
        },{
          text: i18n.t('inventory.titles.locations'), value: "locations", val: "locations"
        },{
          text: i18n.t('inventory.titles.avilable'), value: "available", val: "available"
        },{
          text: i18n.t('inventory.titles.amount'), value: "amount", val: "amount", width: 200, align: "center"
        }]
      },
      amount() {
        return (item) => {
          let s = item.isSelected
          let a = item.available
          let i = this.amounts[item.id]

          if(s) {
            if(!i) return a
            else return (a-i)
          } {
            return item.available
          }
        }
      },
      isValid() {
        let count = 0
        _.forEach(this.valid, (v) => {
          if(!v) count ++
        })

        return (count > 0)
      },
      moreThan() {
        let total = 0
        _.forEach(this.amounts, (a) => {
          if(a)
            total += parseInt(a)
        })

        return total > 0
      }
    },

    methods: {
      ...mapActions({
        fetchLotsNFTs: 'web3/FETCH_LOTS_NFTs',
        fetchWallets: 'web3/FETCH_WALLETS',
        sendTokenTo: 'web3/SEND_TOKEN'
      }),
      orderDatas(list, order) {
        return sortList.orderListByUppercase(list, order)
      },
      async fetchLocations(filters) {
        if (filters) {
          this.primary = null

          this.$set(this.loadings, "primary", true)

          await this.fetchPrimaryLocations([
            filters,
            this.$toast,
          ]).finally(() => {
            this.$set(this.loadings, "primary", false)
          })
        }
      },
      async fetchStorages(filters) {
        if (filters && filters.primaryLocationVersionIds) {
          this.secondary = null
          this.$set(this.loadings, 'secondary', true)

          if (typeof filters.primaryLocationVersionIds === 'object')
            filters.primaryLocationVersionIds = [filters.primaryLocationVersionIds.id]
          else
            filters.primaryLocationVersionIds = [filters.primaryLocationVersionIds]

          for (let property in filters) {
            if (filters[property] === null) {
              delete filters[property]
            }
          }

          await this.fetchStorageLocations([
            filters,
            this.$toast,
          ]).finally(() => {
            this.$set(this.loadings, 'secondary', false)
          })
        }
      },
      async showDialog() {
        this.loadingLots = true

        await this.fetchWallets()

        await this.fetchLotsNFTs({
          action: "BURN",
          contractID: this.token.id,
          product: this.token.product,
        }).finally(() => {
          this.loadingLots = false
          this.dialog = true
        })
      },
      async sendTokens() {
        let _amounts = _.filter(this.amounts, a => { return !!a })
            _amounts = _amounts.map(a => parseInt(a))

        const _references = this.selected.map(s => parseInt(s.reference))
        const _lots = this.selected.map(l => l.lot)

        const data = {
          action: "SEND",
          contractId: this.token.id,
          contractAddress: this.token.contractAddress,
          product: this.token.product,
          sdiDestinationId: this.secondary.storageDeviceId,
          lots: [{
            ...this.selected[0],
            amount: _amounts[0]
          }]
        }

        const payload = {
          data,
          wallet: this.wallet,
          amounts: _amounts,
          references: _references,
          description: _lots
        }

        console.log(payload)

        await this.sendTokenTo(payload).finally(() => {
          this.$root.$emit('loadTokenData', this.token.contractAddress)
          this.closeDialog()
        })
      },
      clearLocations() {
        this.secondary = null
        this.$refs.formLocationToken.resetValidation()
      },
      sum(value) {
        let amount = 0
        if(value) {
          _.forEach(value, (v) => {
            if(v) amount += parseInt(v)
          })
        } else {
          _.forEach(this.amounts, (a) => {
            if(a) amount += parseInt(a)
          })
        }

        return amount
      },
      isEmpty(value, index) {
        if(value === '' || value === null) {
          this.$set(this.amounts, index, 0)
        }
      },
      isSelected(event) {
        _.forEach(this.lots, (l) => {
          l.isSelected = false
        })
        this.amounts = []

        if(!event.value) {
          delete this.amounts[event.item.id]
        }

        _.find(this.lots, { id: event.item.id }).isSelected = event.value

        setTimeout(() => {
          this.amounts = this.amounts.map(a => parseInt(a)).filter(a => !!a)
          this.sum(this.amounts)
        }, 200)
      },
      clearForm() {
        this.amounts = []
        if(this.selected && this.selected.length > 0) {
          _.forEach(this.selected, (l) => {
            l.isSelected = false
          })
          this.$refs.formAmount.resetValidation()
          this.selected = []
        }
        _.forEach(this.lots, (l) => {
          l.isSelected = false
        })

        this.primary = null
      },
      closeDialog() {
        this.loading = false
        this.dialog = false
        this.loadings.send = false
        this.clearForm()
      }
    },

    destroyed() {
      this.$root.$off('sendTokens')
    }
  }
</script>

<style scoped lang="scss">
  .style-dialog {
    .v-dialog:not(.v-dialog--fullscreen) {
      border-radius: 0.5em;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }
  }

  .card {
    border-radius: 0.5em;
  }

  .card-title {
    font-size: 18px;
    padding: 16px;
    color: var(--v-primary-base) !important;
  }
  
  .form-container {
    border: 1px dashed lightgrey;
    border-radius: .3em;
    padding: 8px;
    margin: 0 2px;
  }

  .theme--light.v-label {
    color: #232323 !important;
    margin-left: 4px
  }

  .token-name {
    background: var(--v-t-bw-primary-base, #f3f3f3);
    color: var(--v-primary-base, #005B4A);
    border-radius: .3em 0 0 .3em;
    font-size: 12px;
    font-weight: bold;
  }

  .token-address__default {
    border-radius: 0 .3em .3em 0;
    font-size: 12px;
    background: var(--v-primary-darken2, #005B4A);
    color: white;
    font-weight: bold;
  }

  >>>.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    box-shadow: none !important;
  }

  .v-chip.success {
    border-radius: .3em !important;
    background-color: rgb(117 227 81 / 24%) !important;
    color: seagreen !important;
    font-weight: 800;
  }

  .v-chip.error {
    border-radius: 0.3em !important;
    background-color: rgb(231 11 11 / 15%) !important;
    color: rgb(245 30 30) !important;
    font-weight: 600;
  }

  .v-chip.default {
    border-radius: 0.3em !important;
    font-weight: 600;
  }
</style>