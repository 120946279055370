<template>
  <v-card elevation='0' class='d-flex align-center'>
    <v-overlay absolute color='white' v-model='loading'>
      <v-progress-circular color='secondary' indeterminate />
    </v-overlay>
    <v-card-text>
      <v-row class='ma-2' v-if='token'>
        <v-col cols='12'>
          <div class='d-flex justify-space-between align-center align-content-center'>
            <p class='text__width uppercase my-0 py-0 font-weight-bold'> {{ $t('tokens.buttons.send') }} </p>
            <v-icon color='#29292930' left size='20px'>
              mdi-arrow-right
            </v-icon>
            <p class='amount__text text__width uppercase my-0 py-0'> {{ amount.sent }} <strong>{{ token.symbol
            }}</strong>
            </p>
          </div>
        </v-col>
        <v-col cols='12'>
          <v-divider light></v-divider>
        </v-col>
        <v-col cols='12'>
          <div class='d-flex justify-space-between align-center align-content-center'>
            <p class='text__width uppercase my-0 py-0 font-weight-bold'> {{ $t('tokens.buttons.received') }} </p>
            <v-icon color='#29292930' left size='20px'>
              mdi-arrow-left
            </v-icon>
            <p class='amount__text text__width uppercase my-0 py-0'> {{ amount.received }} <strong>{{ token.symbol
            }}</strong> </p>
          </div>
        </v-col>
        <v-col cols='12'>
          <v-divider light></v-divider>
        </v-col>
        <v-col cols='12'>
          <div class='d-flex justify-space-between align-center align-content-center'>
            <p class='text__width uppercase my-0 py-0 font-weight-bold'> {{ $t('tokens.buttons.settled') }} </p>
            <v-icon color='#29292930' left size='20px'>
              mdi-file-certificate
            </v-icon>
            <p class='amount__text text__width uppercase my-0 py-0'> {{ amount.settled }} <strong>{{ token.symbol
            }}</strong> </p>
          </div>
        </v-col>
      </v-row>
      <v-row class='ma-2' v-else>
        <v-col cols='12'>
          <p class='text-center ma-0 pa-0 black--text font-weight-bold text-uppercase'>{{ $t('tokens.titles.nothing') }}
          </p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'WalletTotalsComponent',

  props: {
    amount: {
      type: Object,
      required: false
    },
    token: {
      type: Object,
      required: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style scoped>
.text__width {
  width: 140px;
  font-size: 16px;
  font-weight: 800;
  color: #292929;
}

.amount__text {
  font-size: 16px;
  font-weight: bold;
  color: #292929;
  text-align: end;
}
</style>